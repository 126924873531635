import { Frame, addPropertyControls, ControlType, RenderTarget } from "framer"
import React, { useEffect, useLayoutEffect, useRef } from "react"

function UncodeHTML(props) {
    const { containerID, customCSS, customScript, customHTML, htmlTag } = props
    const containerRef = useRef(null)
    const styleTagRef = useRef(null)
    const scriptTagRef = useRef(null)

    useLayoutEffect(() => {
        if (containerID) {
            containerRef.current.id = containerID
        }
    }, [containerID])

    useEffect(() => {
        if (styleTagRef.current) {
            document.head.removeChild(styleTagRef.current)
        }
        if (customCSS) {
            const styleTag = document.createElement("style")
            const css = containerID
                ? `#${containerID} { ${customCSS} } ${customCSS}`
                : customCSS
            styleTag.innerHTML = css
            document.head.appendChild(styleTag)
            styleTagRef.current = styleTag
        }
    }, [customCSS, containerID])

    useEffect(() => {
        if (scriptTagRef.current) {
            document.body.removeChild(scriptTagRef.current)
        }
        if (customScript) {
            const scriptTag = document.createElement("script")
            scriptTag.innerHTML = customScript
            document.body.appendChild(scriptTag)
            scriptTagRef.current = scriptTag
        }
    }, [customScript])

    const CustomTag = htmlTag

    useEffect(() => {
        if (
            RenderTarget.current() === RenderTarget.preview ||
            RenderTarget.current() === RenderTarget.canvas
        ) {
            console.log("Rendering in preview or canvas")
        }
    }, [])

    return (
        <CustomTag ref={containerRef}>
            <div dangerouslySetInnerHTML={{ __html: customHTML }} />
        </CustomTag>
    )
}

addPropertyControls(UncodeHTML, {
    containerID: {
        type: ControlType.String,
        title: "Container ID",
        defaultValue: "",
        description:
            "Define the ID for the container / Defina o ID para o contêiner",
    },
    Importante: {
        type: ControlType.String,
        placeholder: "Leia:",
        description:
            "O valor inserido no ContainerID será aplicado à div mais próxima. Ou seja, se você tiver uma div no Framer e este componente estiver dentro dela, a div mais próxima receberá a ID especificada no ContainerID. / Assim, você poderá aplicar CSS customizado, scripts e outras personalizações diretamente a ela. Não é necessário colocar a tag HTML como #Teste { Código aqui }, pois o componente já insere a ID automaticamente. Informe apenas as propriedades de estilo",
    },
    customCSS: {
        type: ControlType.String,
        title: "Custom CSS",
        defaultValue: "",
        displayTextArea: true,
        description: "Insert custom CSS here / Insira o CSS personalizado aqui",
    },
    customScript: {
        type: ControlType.String,
        title: "Custom Script",
        defaultValue: "",
        displayTextArea: true,
        description:
            "Insert custom JavaScript here / Insira o JavaScript personalizado aqui",
    },
    customHTML: {
        type: ControlType.String,
        title: "Custom HTML",
        defaultValue: "",
        displayTextArea: true,
        description:
            "Insert custom HTML here / Insira o HTML personalizado aqui",
    },
    htmlTag: {
        type: ControlType.Enum,
        title: "HTML Tag",
        options: [
            "div",
            "section",
            "article",
            "aside",
            "header",
            "footer",
            "nav",
            "main",
        ],
        optionTitles: [
            "div",
            "section",
            "article",
            "aside",
            "header",
            "footer",
            "nav",
            "main",
        ],
        defaultValue: "div",
        description:
            "Select the HTML tag to use / Selecione a tag HTML a ser usada",
    },
    autor: {
        type: ControlType.String,
        title: "Autor",
        defaultValue: "Autor:",
        description:
            "Code by UncodeStack and @omatusaelhorch. / Code by UncodeStack e @omatusaelhorch.",
    },
})

export default UncodeHTML

// Code by @omatusaelhorch
